// What browser is being used?
navigator.sayswho = (() => {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null)
            return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
    M = M[2]
        ? [M[1], M[2]]
        : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null)
        M.splice(1, 1, tem[1]);
    return M.join(' ');
})();

let initializeMobileNav = () => {
  let menuToggles = query('.menu-toggle')
  let navs = query('header nav')
  menuToggles.forEach(menuToggle => {
    menuToggle.addEventListener('click', e => {
      if (hasClass('close', menuToggle)) {
        navs.forEach(nav => {
          removeClass('show', nav)
          removeClass('show', query('.sub', nav))
        })
        menuToggles.forEach(mT => {
          removeClass('close', mT)
        })
        removeClass('nav-open', document.body)
      } else {
        // removeClass('visible', document.querySelector('#tray'))
        navs.forEach(nav => {
          addClass('show', nav)
        })
        menuToggles.forEach(mT => {
          addClass('close', mT)
        })
        addClass('nav-open', document.body)
        window.scrollTo(0, 0)
      }
    })
  })
  let navsWithSubNav = query('.nav__wrapper > .sub > :not(.sub__wrapper) > a')
  navsWithSubNav.forEach(navItem => {
    navItem.addEventListener('click', e => {
      if (window.matchMedia('(max-width: 1023px)').matches) {
        e.preventDefault()
        addClass('show', closest('.sub', navItem))
      }
    })
  })
  let subNavBacks = query('.nav__wrapper [href="#nav-back"]')
  subNavBacks.forEach(navBack => {
    navBack.addEventListener('click', e => {
      if (window.matchMedia('(max-width: 1023px)').matches) {
        e.preventDefault()
        let sub = closest('.sub', navBack)
        removeClass('show', sub)
      }
    })
  })
}

loadjs([
    '/assets/scripts/array.from.js',
    '/assets/scripts/functions.js',
  ], {
  async: false,
  success: () => {

    load(() => {
      masonry('.card__wrapper.masonry', '.card')
    })

    loadjs('/assets/scripts/matchMedia.js')
    // loadjs('/assets/scripts/object-assign.js', 'objectAssign',
    // {
    //   success: () => {
    //     window.ObjectAssign.polyfill()
    //   }
    // })

    loadjs('/assets/scripts/jquery.js', 'jQuery', {async: false})
    loadjs([
        '/assets/scripts/slick.js', '/assets/vendor/slick-carousel/slick.css'
    ], 'slick', {async: false})

    if (document.querySelectorAll('[map-api-key]').length > 0) {
        let mapApiKey = document.querySelector('[map-api-key]').getAttribute('map-api-key')
        loadjs('//maps.google.com/maps/api/js?key=' + mapApiKey, 'googlemaps')
    }

    loadjs([
        '/assets/scripts/lazysizes.js', '/assets/scripts/lazysizes-plugins/ls.bgset.js', '/assets/scripts/lazysizes-plugins/ls.respimg.min.js', '/assets/scripts/lazysizes-plugins/ls.attrchange.min.js', '/assets/scripts/mapStyles.js'
    ], {
        async: false,
        success: () => {
            document.addEventListener('lazybeforeunveil', e => {
                let bg = e.target.getAttribute('data-bg');
                if (bg) {
                    e.target.style.backgroundImage = 'url(' + bg + ')';
                }
                if (e.target.getAttribute('map-api-key')) {
                    loadjs.ready('googlemaps', {
                        success: () => {
                            let mapElements = document.querySelectorAll('[address]');
                            Array.prototype.forEach.call(mapElements, (el, i) => {
                                let address = el.getAttribute('address');
                                let lat = parseFloat(el.getAttribute('lat'));
                                let lng = parseFloat(el.getAttribute('lng'));
                                let map = new google.maps.Map(el, {
                                    center: {
                                        lat: lat,
                                        lng: lng
                                    },
                                    zoom: 16,
                                    styles: mapStyles
                                });

                                // let plaucheIcon = new google.maps.MarkerImage("data:image/svg+xml;charset=utf8,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 41'%3E%3Cdefs%3E%3Cstyle%3E.cls-1{fill:%238cc63e;}%3C/style%3E%3C/defs%3E%3Ctitle%3EArtboard 1%3C/title%3E%3Cpath class='cls-1' d='M17.55.88A15.94,15.94,0,0,1,29.19,5.64,15.58,15.58,0,0,1,34,17.13a15.58,15.58,0,0,1-4.81,11.5L17.5,40.12,5.86,28.63A15.51,15.51,0,0,1,1,17.13,15.51,15.51,0,0,1,5.86,5.64,16.11,16.11,0,0,1,17.55.88Zm0,21.65a5.16,5.16,0,0,0,5.2-5.13,5.24,5.24,0,0,0-5.2-5.18,5,5,0,0,0-3.62,1.54,5.08,5.08,0,0,0,0,7.28A4.92,4.92,0,0,0,17.5,22.53Z'/%3E%3C/svg%3E", null, null, null, new google.maps.Size(35,41));
                                // var plaucheIcon = {
                                //   url: 'data:image/svg+xml;charset=utf8,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 41'%3E%3Cdefs%3E%3Cstyle%3E.cls-1{fill:%238cc63e;}%3C/style%3E%3C/defs%3E%3Ctitle%3EArtboard 1%3C/title%3E%3Cpath class='cls-1' d='M17.55.88A15.94,15.94,0,0,1,29.19,5.64,15.58,15.58,0,0,1,34,17.13a15.58,15.58,0,0,1-4.81,11.5L17.5,40.12,5.86,28.63A15.51,15.51,0,0,1,1,17.13,15.51,15.51,0,0,1,5.86,5.64,16.11,16.11,0,0,1,17.55.88Zm0,21.65a5.16,5.16,0,0,0,5.2-5.13,5.24,5.24,0,0,0-5.2-5.18,5,5,0,0,0-3.62,1.54,5.08,5.08,0,0,0,0,7.28A4.92,4.92,0,0,0,17.5,22.53Z'/%3E%3C/svg%3E',
                                //   // This marker is 20 pixels wide by 32 pixels high.
                                //   size: new google.maps.Size(20, 32),
                                //   // The origin for this image is (0, 0).
                                //   origin: new google.maps.Point(0, 0),
                                //   // The anchor for this image is the base of the flagpole at (0, 32).
                                //   anchor: new google.maps.Point(0, 32)
                                // };

                                let marker = new google.maps.Marker({
                                    position: {
                                        lat: lat,
                                        lng: lng
                                    },
                                    icon: {
                                      size: new google.maps.Size(35, 42),
                                      scaledSize: new google.maps.Size(35, 42),
                                      origin: new google.maps.Point(0, 0),
                                      url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAqCAMAAAAOCBKjAAABO1BMVEUAAAD///+Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj6Mxj5tRUX/AAAAaHRSTlMAAAECBAUGBwgLDxESFRgaGxweIyQlJictNz1CRUZHSU1OUlRVVlhbXF9iaG1vcHF0dnl8fX6AhYqSlZifoKGjsLK2ur2+wMLExcfJysvP0dbX2t3e3+bn6Orr7O3x8vT19vf4+fr7/cjQfjsAAAFfSURBVHjahdTXVgIxEIDhQRA7KjYQFbFjw4KKBREbdhSsKOjqrsz7P4EDLEzCZtf/LnO+q+RMwAVNtTYPXKJpi+xevSHix83+TIfS+BJF5D73/BbjjpVQ7jvukU33CVq78Imm/wFVPQ6y6cmhuqe+uvFk0K5rr2m20b6dmhnQHczvSNUcoFPJiun8cTR6F5koimVWppdPUWyRTBI5Yx4qTWnIpchkkYsSqCLksmSEd7qFemc8LJFBLt4wq8jJZqlhFmSji5dab4OHOpk8H3Mek7Tc8TBPJo3clmliyKXJrElP2E7Cu1lGbp3MEIq9HCaSzyg2TAbO0alLqJgJRxOuGjhyIMdQM72vtuTdbxoY1WyIMca7M2koSXlW3MGIChlz8i6HNSuJgGwgpFkJGzXSxsFiCJVEElL/UUH+gL6YsJFRMQhqw4gJGy5QIFJgwkZGhQCoDaN7JoKRc4PV/N8fto1Mpv/XzpMAAAAASUVORK5CYII=",
                                      anchor: new google.maps.Point(17, 36)
                                    },
                                    map: map,
                                    title: 'MARKER_TITLE'
                                });

                                let infowindow = new google.maps.InfoWindow({
                                    content: '<a href="https://www.google.com/maps/place/' + encodeURIComponent(address) + '" target="_blank">View on Google Maps</a>',
                                    pixelOffset: new google.maps.Size(0,0)
                                });
                                marker.addListener('click', () => {
                                    infowindow.open(map, marker);
                                });
                            });
                        }
                    });
                }
            });
        }
    });

    loadjs.ready([// 'objectAssign',
        'jQuery'], {
        success: () => {
            // DOM Element is ready for modifications.
            $(document).ready(() => {
                // masonry('.card__wrapper.masonry', '.card')

                // Stop placeholder links from affecting the url.
                let elements = document.querySelectorAll('[href="#"]');
                Array.prototype.forEach.call(elements, (el, i) => {
                    el.addEventListener('click', (e) => {
                        e.preventDefault();
                    });
                });

                intellectualPropertySymbols();

                // Add current browser to document classes
                document.documentElement.className += ' ' + navigator.sayswho.toLowerCase().replace(' ', '-');

                // Match height
                matchHeight('.hcb--content h1');
                matchHeight('.hcb--content p');
                matchHeight('.hcb--content a.button.button--feature');

                // Initialize Accordion Lists
                initAccordionLists()

                // Initialize Mobile Nav
                initializeMobileNav();

                performancePointerEvents();

                query('[href="#top"]').forEach(backToTop => {
                  backToTop.addEventListener('click', e => {
                    e.preventDefault()
                    scrollToEl(query('body')[0], 250)
                  })
                })

                // Fallback in case font doesn't load fast enough and the window is still loading.
                setTimeout(() => {
                    let el = document.getElementsByTagName('body')[0];
                    let classToDel = 'fonts-loading';
                    if (el.classList) {
                        el.classList.remove(classToDel);
                    } else {
                        el.className = el.className.replace(new RegExp('(^|\\b)' + classToDel.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
                    }
                }, 2000);

            });

            let showMorePracticeAreas = query('.practice-area__wrapper .show-more')
            showMorePracticeAreas.forEach(showMore => {
              showMore.addEventListener('click', e => {
                e.preventDefault()
                showMore.style.display = 'none'
                let hiddenLis = query('aside ul li.hidden', closest('practice-area__wrapper'))
                let delay = 0
                hiddenLis.forEach(li => {
                  setTimeout(() => {
                    removeClass('hidden', li)
                  }, delay)
                  delay += 50
                })
              })
            })

            // When the browser window is resized.
            // $(window).resize(() => {});

            // When the device changes orientation.
            // $(window).on('orientationchange', (e) => {});

            initializeFormNameCombiner();
            let inputs = document.querySelectorAll('form input');
            let textareas = document.querySelectorAll('form textarea');

            initFloatingLabels()

            Object.keys(inputs).forEach(i => {
                let input = inputs[i];
                input.addEventListener('blur', validateField);
                input.addEventListener('keyup', validateField);
            });
            Object.keys(textareas).forEach(i => {
                let textarea = textareas[i];
                textarea.addEventListener('blur', validateField);
                textarea.addEventListener('keyup', validateField);
            });
            let submits = document.querySelectorAll('form [href="#submit"]');
            Object.keys(submits).forEach((i) => {
                let submit = submits[i];
                submit.addEventListener('click', function(e) { // Cannot be an arrow function because of $(this) usage
                    e.preventDefault();
                    var form = $(this).closest('form')[0]
                    if (form && form.checkValidity()) {
                        $(this).closest('form').submit();
                    } else {
                        let inputs = document.querySelectorAll('input');
                        let textareas = document.querySelectorAll('textarea');
                        let blurevent = document.createEvent("HTMLEvents");
                        blurevent.initEvent("blur", true, true);
                        blurevent.eventName = "blur";

                        Object.keys(Object.assign(inputs, textareas)).forEach(i => {
                            let input = inputs[i];
                            input.dispatchEvent(blurevent);
                        });

                        let firstErrorEl = document.querySelector('.error').getBoundingClientRect();
                        if (firstErrorEl) {
                            window.scrollTo(window.scrollX + firstErrorEl.left, window.scrollY + firstErrorEl.top);
                        }
                    }
                });
            });
        }
    });

    //sticky nav
    if (document.querySelectorAll('.section--content-nav').length > 0) {
        loadjs(['/assets/scripts/stickybits.min.js'], {
            success: () => {
                // stickybits('.section--content-nav', {stickyBitStickyOffset: 28})
                let contentNavs = query('.section--content-nav')
                contentNavs.forEach(contentNav => {
                  let links = contentNav.querySelectorAll('.sticky-nav li a')
                  links.forEach(link => {
                    link.style.position = 'absolute'
                    link.addEventListener('click', e => {
                      e.preventDefault()
                      let el = document.getElementById(link.getAttribute('href').substr(1))
                      scrollToEl(el, 125)
                    })
                  })
                })
                let stickyNav = throttle(5, () => {
                  contentNavs.forEach(contentNav => {
                    let frame = contentNav.parentNode
                    let framePos = frame.getBoundingClientRect()
                    let links = contentNav.querySelectorAll('.sticky-nav li a')
                    let i = 0
                    let beforeOffset = 16
                    links.forEach(link => {
                      let afterOffset = 16
                      for (var n = i+1; n < links.length; n++) {
                        afterOffset += links[n].offsetHeight
                      }
                      let el = document.getElementById(link.getAttribute('href').substr(1))
                      let matchPosY = el.getBoundingClientRect().y - framePos.y
                      let linkPosY = framePos.y + matchPosY
                      if (linkPosY < beforeOffset) {
                        matchPosY = matchPosY - linkPosY + beforeOffset
                        if (matchPosY > (frame.offsetHeight - link.offsetHeight - afterOffset)) matchPosY = frame.offsetHeight - link.offsetHeight - afterOffset
                      }
                      if (linkPosY > (window.innerHeight - link.offsetHeight - afterOffset)) {
                        matchPosY = matchPosY - (linkPosY - window.innerHeight) - link.offsetHeight - afterOffset
                        if (matchPosY < beforeOffset) matchPosY = beforeOffset
                      }
                      link.style.top = matchPosY + 'px'

                      beforeOffset += link.offsetHeight
                      i++
                    })
                  })
                })
                ready(() => {
                  stickyNav()
                  setTimeout(stickyNav, 50)
                })
                window.addEventListener('scroll', stickyNav)
                window.addEventListener('resize', stickyNav)
            }
        })
    }

    //animation aos
    if (document.querySelectorAll('[data-aos]').length > 0) {
        loadjs([
            '/assets/scripts/aos.js', '/assets/styles/aos.css'
        ], {
            success: () => {
                AOS.init(
                  {
                    disable: 'mobile',
                    once: 'true',
                  }
                );
                load(() => {
                  AOS.refresh()
                })
            }
        });
    }

    if (document.querySelectorAll('.bios--slider').length > 0) {
      loadjs(['/assets/scripts/lory.js'], {
        success: () => {
          let biosSliders = query('.bios--slider')
          let dotListItem = document.createElement('li')
          addClass('controls__dot', dotListItem)
          biosSliders.forEach(biosSlider => {
            let dotCount = query('.listing__item', biosSlider).length
            let dotContainer = biosSlider.querySelector('.controls__dots')
            let showCount = parseInt(biosSlider.getAttribute('data-show-count'))
            let handleDotEvent = e => {
              if (e.type === 'before.lory.init') {
                for (var i = 0, len = dotCount; i < len; i++) {
                  var clone = dotListItem.cloneNode()
                  dotContainer.appendChild(clone)
                }
                for (var i = 0; i < showCount; i++) {
                  addClass('active', dotContainer.childNodes[i])
                }
              }
              if (e.type === 'after.lory.init') {
                for (var i = 0, len = dotCount; i < len; i++) {
                  dotContainer.childNodes[i].addEventListener('click', e => {
                    let slideToIndex = Array.prototype.indexOf.call(dotContainer.childNodes, e.target)
                    if (slideToIndex > dotCount - showCount) slideToIndex = dotCount - showCount
                    bioSliderLory.slideTo(slideToIndex)
                  })
                }
              }
              if (e.type === 'after.lory.slide') {
                removeClass('active', dotContainer.childNodes)

                for (var i = 0; i < showCount; i++) {
                  addClass('active', dotContainer.childNodes[e.detail.currentSlide+i])
                }
              }
              if (e.type === 'on.lory.resize') {
                  removeClass('active', dotContainer.childNodes)

                  for (var i = 0; i < showCount; i++) {
                    addClass('active', dotContainer.childNodes[i])
                  }
              }
            }
            biosSlider.slideCount = biosSlider.getElementsByClassName('listing__item').length
            biosSlider.addEventListener('before.lory.init', handleDotEvent)
            biosSlider.addEventListener('after.lory.init', handleDotEvent)
            biosSlider.addEventListener('after.lory.slide', handleDotEvent)
            biosSlider.addEventListener('on.lory.resize', handleDotEvent)
            let bioSliderLory = lory(biosSlider, {
              classNameFrame: 'bios__listing',
              classNameSlideContainer: 'listing__container',
              enableMouseEvents: true
            })
          })
        }
      })
    }

    //animation rellax
    if (window.matchMedia('(min-width: 769px)').matches) {
      if (document.querySelectorAll('.rellax').length > 0) {
          loadjs('/assets/scripts/rellax.min.js', {
              success: () => {
                  var rellax = new Rellax('.rellax');
              }
          });
      }
    }

    if (document.querySelectorAll('.slider').length > 0) {
      loadjs(['/assets/scripts/lory.js'], {
        success: () => {
          // let imageSliders = query('.image-slider')
          // let imageSliderLory = lory(imageSlider, {
          //   classNameFrame: 'image-slides',
          //   classNameSlideContainer: 'image-slides__container',
          //   enableMouseEvents: true
          // })
          var simple = document.querySelector('.js_percentage');

          lory(simple, {
              infinite: 1,
              enableMouseEvents: true
          });
        }
      })
    }



    // if (document.getElementsByClassName('slider').length > 0) {
    //     loadjs.ready([
    //         'jQuery', 'slick'
    //     ], {
    //         success: () => {
    //             // Slick Carousel stuff
    //             $('.slider').slick({
    //                 dots: true,
    //                 adaptiveHeight: true,
    //                 responsive: [
    //                     {
    //                         breakpoint: 480,
    //                         settings: {
    //                             arrows: false
    //                         }
    //                     }
    //                 ]
    //             });
    //         }
    //     });
    // }

    let initFloatingLabels = () => {
        let inputs = [
            ...document.querySelectorAll('label > input'),
            ...document.querySelectorAll('label > textarea')
        ]
        inputs.forEach(input => {
            let span = query('span', input.parentNode)[0]
            if (span) {
                input.addEventListener('focus', e => {
                    let input = e.target
                    addClass('above', span)
                })
                input.addEventListener('blur', e => {
                    let input = e.target
                    if (input.value === '') {
                        removeClass('above', span)
                    }
                })
                input.addEventListener('change', e => {
                    let input = e.target
                    if (input.value === '') {
                        removeClass('above', span)
                    } else {
                        addClass('above', span)
                    }
                })
                if (input.value === '') {
                    removeClass('above', span)
                } else {
                    addClass('above', span)
                }
            }
        })
    }

    // Inlcude jQuery placeholder on pages that have the placeholder attribute.
    if (document.querySelectorAll('[placeholder]').length > 0) {
        loadjs.ready('jQuery', {
            success: () => {
                loadjs('/assets/scripts/jquery.placeholder.js')
            }
        })
    }

    // Include splitview javascript on pages with split views
    if (document.querySelectorAll('.split-view').length > 0) {
        loadjs('/assets/scripts/splitview.js');
    }

    // include dropkick dropdowns
    if (document.querySelectorAll('select').length > 0) {
        loadjs('/assets/scripts/dropkick.min.js', {
            success: () => {
                let selects = document.querySelectorAll('select');
                Object.keys(selects).forEach(i => {
                    let select = selects[i];
                    new Dropkick(select,{mobile:true});
                });
            }
        });
    }

    // Inlcude Video.js on pages that use video-js
    if (document.getElementsByClassName('video-js').length > 0) {
        loadjs(['/assets/scripts/video.js', '/assets/vendor/video-js/video-js.css', '/assets/styles/video.css']);
    }

    // animate text start
    //if element is inview function
    function isInView(elem) {
        // jquery version:
        // return $(elem).offset().top - $(window).scrollTop() < $(elem).height() ;
        // javascript version only:
        let rect = elem.getBoundingClientRect();
        return rect.top - window.scrollY < elem.offsetHeight / 2;
    }
    // init Count Up Function
    function initCountUp(elem) {
        let isNum = /^\d+$/.test(elem.innerHTML);
        if (isNum) {
            var options = {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.'
            };

            var startCountUp = new CountUp(elem, 0, elem.innerHTML, 0, 4, options);
            if (!startCountUp.error) {
                startCountUp.start();
            } else {
                console.error(startCountUp.error);
            }
        }
    }

    if (document.querySelectorAll('.feed__item.feed__item--number div h1').length > 0) {
        loadjs([
            '/assets/scripts/countUp.min.js', '/assets/scripts/jquery.js'
        ], {
            success: () => {

                let numberStats = document.querySelectorAll('.feed__item.feed__item--number div h1');
                vars = {};

                window.addEventListener('scroll', throttle(25, function() {
                    Object.keys(numberStats).forEach(i => {
                        let numberStat = numberStats[i];
                        if (isInView(numberStat)) {
                            //fire whatever you what
                            if (!hasClass('animated', numberStat)) {
                                addClass('animated', numberStat)
                                initCountUp(numberStat);
                            }
                        }
                    })
                }))
                Object.keys(numberStats).forEach(i => {
                    let numberStat = numberStats[i];

                    //fraction count up Animation settings
                    // var options = {
                    //   useEasing: true,
                    //   useGrouping: true,
                    //   separator: ',',
                    //   decimal: '/',
                    // };
                    // var demo = new CountUp('myTargetElement', 0, 4.5, 1, 2.5, options);
                    // if (!demo.error) {
                    //   demo.start();
                    // } else {
                    //   console.error(demo.error);
                    // }

                    //suffix multiplyer settings
                    // var options = {
                    //   useEasing: true,
                    //   useGrouping: true,
                    //   separator: ',',
                    //   decimal: '.',
                    //   suffix: 'x'
                    // };
                    // var demo = new CountUp('myTargetElement', 0, 5, 0, 2.5, options);
                    // if (!demo.error) {
                    //   demo.start();
                    // } else {
                    //   console.error(demo.error);
                    // }

                });
            }
        });
    }

    // animate text end

    function performancePointerEvents() {
      //https://www.thecssninja.com/css/pointer-events-60fps
      var body = document.body, timer;

      window.addEventListener('scroll', function() {
        clearTimeout(timer);
        if(!body.classList.contains('disable-hover')) {
          body.classList.add('disable-hover')
        }

        timer = setTimeout(function(){
          body.classList.remove('disable-hover')
        },500);
      }, false);
    }

    function matchHeight(selector) {
        let heights = {};
        let elements = document.querySelectorAll(selector);
        Array.prototype.forEach.call(elements, (el, i) => {
            el.style.height = '';
            if (heights[el.offsetTop] === undefined || el.offsetHeight > heights[el.offsetTop]) {
                heights[el.offsetTop] = el.offsetHeight;
            }
        });
        Array.prototype.forEach.call(elements, (el, i) => {
            el.style.height = heights[el.offsetTop] + 'px';
        });
    }


    function intellectualPropertySymbols() {
        $("h1,h2,h3,h4,h5,h6,p,a,span,li").each(function() {
            let html = $(this).html();
            let re = new RegExp(/&reg;|®|&trade;|™|&copy;|©/gi)
            if (re.test(html)) {
                $(this).html(html.replace(/&reg;/gi, '<sup>&reg;</sup>').replace(/®/gi, '<sup>&reg;</sup>').replace(/&trade;/gi, '<sup>&trade;</sup>').replace(/™/gi, '<sup>&trade;</sup>').replace(/&copy;/gi, '<sup>&copy;</sup>').replace(/©/gi, '<sup>&copy;</sup>').replace(/(<sup>)+/gi, '<sup>').replace(/(<\/sup>)+/gi, '</sup>'));
            }
        });
    }

    function initializeFormNameCombiner() {
        $('input[name="fromName"],input[name="lastName"]').on('blur change keyup', e => {
            $('input[name="message[Name]"]')[0].value = ($('input[name="fromName"]')[0].value + " " + $('input[name="lastName"]')[0].value).trim();
        });
        $('input[name="fromEmail"]').on('blur change keyup', function(e) {
            $('input[name="message[Email]"]')[0].value = $(this)[0].value;
        });
    }

    function validateField() {
        if (this.checkValidity()) {
            this.classList.remove('error');
        } else {
            this.classList.add('error');
        }
    }

    function initAccordionLists() {
      let accordionLists = [...document.getElementsByClassName('accordion__list')]
      accordionLists.forEach(accordionList => {
        let accordionItems = [...accordionList.getElementsByClassName('accordion__item')]
        let closeItems = () => {
          accordionItems.forEach(accordionItem => {
            let accordionContent = accordionItem.querySelector('.accordion__content')
            removeClass('active', accordionItem)
            accordionContent.style.height = ''
          })
        }
        accordionItems.forEach(accordionItem => {
          accordionItem.addEventListener('click', e => {
            if (hasClass('accordion__item', e.target) || closest('.accordion__item', e.target)) {
              let accordionContent = accordionItem.querySelector('.accordion__content')
              if (hasClass('active', accordionItem)) {
                removeClass('active', accordionItem)
                accordionContent.style.height = ''
              } else {
                closeItems()
                accordionContent.style.transition = 'none'
                addClass('active', accordionItem)
                let height = accordionContent.offsetHeight
                removeClass('active', accordionItem)
                accordionContent.style.transition = ''
                setTimeout(() => {
                  accordionContent.style.height = height + 'px'
                  addClass('active', accordionItem)
                }, 5)
              }
            }
          })
        })
      })
    }
  }
})
